export default [
  ['B', 'Z', 'X', 'S', 'N', 'K'],
  ['H', 'R', 'N', 'R', 'H', 'N'],
  ['N', 'O', 'N', 'I', 'I', 'Y'],
  ['C', 'B', 'T', 'C', 'J', 'D'],
  ['E', 'A', 'E', 'O', 'O', 'A'],
  ['T', 'T', 'C', 'C', 'S', 'M'],
  ['R', 'F', 'D', 'L', 'L', 'W'],
  ['T', 'T', 'W', 'H', 'P', 'H'],
  ['A', 'I', 'U', 'E', 'O', 'U'],
  ['L', 'G', 'R', 'G', 'D', 'R'],
  ['B', 'L', 'M', 'M', 'Y', 'L'],
  ['P', 'F', 'P', 'K', 'G', 'V'],
];