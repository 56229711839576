/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */
export default class Controls {
  /**
   * #controls #shuffle, #controls #reset, #controls #giveup, #controls #share
   */
  #element = document.querySelector('#controls');
  #game;

  constructor(game) {
    this.#game = game;
    this.#listen();
  }

  transitionToPostGame() {
    this.#element.querySelector('#shuffle').disabled = true;
    this.#element.querySelector('#reset').disabled = true;
    this.#element.querySelector('#giveup').disabled = true;
  }

  #listen() {
    this.#element.addEventListener('click', event => {
      if (event.target.matches('#giveup')) {
        return this.#giveUp(event.target);
      }
      if (event.target.matches('#shuffle')) {
        return this.#game.pool.shuffle(event.target);
      }
      if (event.target.matches('#reset')) {
        return this.#game.grid.reset(event.target);
      }
      if (event.target.matches('#share')) {
        return this.#share(event.target);
      }
    });
  }
  async #giveUp() {
    await this.#game.checkVictory();
    if (this.#game.isVictorious) { return; }
    this.#game.grid.highlightProblems();
    this.#game.isLost = true;
  }
  get #shareEmoji() {
    const nonProblematicCount = this.#game.grid.letterCount - this.#game.grid.problemCount;

    return `Cross ’em ${this.#game.iteration} ${nonProblematicCount}/${this.#game.pool.letterCount}

${this.#game.pool.emoji}

${this.#game.grid.emoji}`
  }
  set #shareEmoji(value) {
    throw new Error('You may not set #shareEmoji.', value);
  }
  #execCommandShare(text) {
    console.debug('Falling back to execCommand-based clipboard share.');
    const copiedEl = this.#element.querySelector('#copied');
    const resultEl = this.#element.querySelector('#result');
    resultEl.value = text;
    resultEl.select();
    document.execCommand('copy');
    copiedEl.classList.add('visible');
    window.setTimeout(() => copiedEl.classList.remove('visible'), 3000);
  }
  #share() {
    console.debug('Sharing result.');
    console.log(this.#shareEmoji);
    const shareData = { text: this.#shareEmoji };
    const navigatorShareIsAwful = navigator.userAgentData?.platform === 'Windows';
    if (!navigator.share || navigatorShareIsAwful || !navigator.canShare || !navigator.canShare(shareData)) {
      console.debug('Navigator Share API not available or is awful or is unable to share.');
      return this.#execCommandShare(shareData.text);
    }
    try {
      console.debug('Using Navigator Share API.');
      navigator.share(shareData);
      return;
    }
    catch (err) {
      console.error('Error with Navigator Share API.', err);
    }
    return this.#execCommandShare(shareData.text);
  }
}
