/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */
import Pool from './pool.js';
import Grid from'./grid.js';
import Controls from './controls.js';
import Data from './data.js';
import { MS_PER_DAY, datasetOrDelete } from './_utils.js';

export default class Game {
  #wordlist;
  /**
   * #game[data-seed][data-iteration][data-victory][data-placing] { --letter-width; }
   *
   * typeof td.dataset.seed === 'string'
   * typeof td.dataset.iteration === 'number'
   * ['true', 'false', undefined].includes(td.dataset.victory)
   * ['true', undefined].includes(td.dataset.placing)
   */
  #element = document.querySelector('#game');
  controls = new Controls(this);
  pool = new Pool(this);
  grid = new Grid(this);
  data = new Data(this);

  constructor() {
    this.#init();
  }
  async #init() {
    console.debug('Initializing game...');
    const wordlistPromise = this.#fetchWordlist();
    this.pool.generate(this.#generateSeed());
    this.data.load();
    await wordlistPromise;
    await this.checkVictory();
  }
  get isOver() {
    return this.isVictorious || this.isLost;
  }
  set isOver(value) {
    if (value) {
      throw new Error('Please set isVictorious or isLost instead.', value);
    }
    delete this.#element.dataset.victory;
    return false;
  }
  get isVictorious() {
    return this.#element.dataset.victory === 'true';
  }
  set isVictorious(value) {
    this.#element.dataset.victory = Boolean(value);
    this.#transitionToPostGame();
    return value;
  }
  get isLost() {
    return this.#element.dataset.victory === 'false';
  }
  set isLost(value) {
    this.isVictorious = !value;
    return value;
  }
  get isPlacing() {
    return this.#element.dataset.hasOwnProperty('placing');
  }
  set isPlacing(value) {
    if (value) {
      this.#element.dataset.placing = true;
    }
    else {
      delete this.#element.dataset.placing;
    }
    return value;
  }
  get letterWidth() {
    return parseInt(this.#element.style.getPropertyValue('--letter-width'));
  }
  set letterWidth(value) {
    this.#element.style.setProperty('--letter-width', `${value}rem`);
    return value;
  }
  get iteration() {
    return this.#element.dataset.iteration;
  }
  set iteration(value) {
    return datasetOrDelete(this.#element, 'iteration', value);
  }
  get seed() {
    return this.#element.dataset.seed;
  }
  set seed(value) {
    return datasetOrDelete(this.#element, 'seed', value);
  }
  problemWithWord(word) {
    if (word.length < 3) {
      return 'length';
    }
    if (!this.#wordlist?.includes(word)) {
      return 'spelling';
    }
    return null;
  }
  async moveMade() {
    this.grid.update();
    this.grid.removeProblems();
    this.data.save();
    await this.checkVictory();
  }
  async checkVictory() {
    if (!this.#wordlist) {
      console.debug('Wordlist has not loaded yet. Fetching now.');
      await this.#fetchWordlist();
    }

    if (this.pool.unusedCount > 0) {
      console.debug('Victory has not been achieved because letters remain in the pool.');
      this.isOver = false;
      return;
    }
    this.grid.highlightProblems();
    if (this.grid.problemCount === 0) {
      this.isVictorious = true;
      return;
    }
    console.debug('Victory has not been achieved because the grid has problems.');
    this.isOver = false;
  }

  #transitionToPostGame() {
    this.controls.transitionToPostGame();
    this.data.transitionToPostGame();
  }
  #generateSeed() {
    const now = new Date();
    this.#element.dataset.seed = now.toISOString().split('T')[0];
    const msSinceLaunch = new Date(this.#element.dataset.seed + 'T00:00:00Z') - new Date('2022-02-19T00:00:00Z');
    this.iteration = Math.floor(Math.abs(msSinceLaunch / MS_PER_DAY));
    return this.#element.dataset.seed;
  }
  #fetchWordlist() {
    return fetch('./wordlist.txt').then(response => response.text()).then(wordlist => {
      this.#wordlist = wordlist.split('\n');
    });
  }
}
