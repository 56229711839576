/*!
 * CE Utils
 * Copyright 2022 Kerrick Long
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 * https://cross-em.com
 */

export const trimFirstAndLast = (_, i, arr) => i !== 0 && i !== arr.length - 1;

export const times = n => Array.from({ length: n }).map((_, i) => i);

export const adjacent = tdEl => {
  const left = tdEl.previousElementSibling || null;
  const right = tdEl.nextElementSibling || null;
  const up = tdEl.parentNode.previousElementSibling?.children[[...tdEl.parentNode.children].indexOf(tdEl)] || null;
  const down = tdEl.parentNode.nextElementSibling?.children[[...tdEl.parentNode.children].indexOf(tdEl)] || null;
  return { up, down, left, right };
};

export const followDown = tdEl => {
  const { down } = adjacent(tdEl);
  if (down?.dataset.status !== 'placed') { return tdEl; }
  return [tdEl, followDown(down)].flat();
}

export const followRight = tdEl => {
  const { right } = adjacent(tdEl);
  if (right?.dataset.status !== 'placed') { return tdEl; }
  return [tdEl, followRight(right)].flat();
}

export const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const msToChunks = ms => {
  let remainder = ms;
  const hours = Math.floor(remainder / (1000 * 60 * 60));
  remainder = remainder - (hours * 1000 * 60 * 60);
  const minutes = Math.floor(remainder / (1000 * 60));
  remainder = remainder - (minutes * 1000 * 60);
  const seconds = Math.floor(remainder / (1000));
  remainder = remainder - (seconds * 1000);
  return { hours, minutes, seconds, ms: remainder };
}

export const padTimeChunk = num => {
  const str = `${num}`;
  switch (str.length) {
    case 0:
      return `00`;
    case 1:
      return `0${str}`;
    default:
      return str;
  }
}

export const tail = arr => arr[arr.length - 1];

export const datasetOrDelete = (element, property, value) => {
    if (value) {
      element.dataset[property] = value;
      return value;
    }
    delete element.dataset[property];
    return null;
}