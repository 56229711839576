export default class ShareUI {
  static #emoji = {
    squares: {
      black: '⬛',
      green: '🟩',
      orange: '🟧',
      yellow: '🟨',
      white: '⬜',
      brown: '🟫',
      purple: '🟪',
      blue: '🟦',
      red: '🟥',
    },
    letters: {
      a: '🇦',
      b: '🇧',
      c: '🇨',
      d: '🇩',
      e: '🇪',
      f: '🇫',
      g: '🇬',
      h: '🇭',
      i: '🇮',
      j: '🇯',
      k: '🇰',
      l: '🇱',
      m: '🇲',
      n: '🇳',
      o: '🇴',
      p: '🇵',
      q: '🇶',
      r: '🇷',
      s: '🇸',
      t: '🇹',
      u: '🇺',
      v: '🇻',
      w: '🇼',
      x: '🇽',
      y: '🇾',
      z: '🇿',
    },
  }
  static get mode() {
    return Boolean(window.matchMedia?.('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light';
  }
  static get isDarkMode() {
    return ShareUI.mode === 'dark';
  }
  static get isLightMode() {
    return ShareUI.mode === 'light';
  }
  static get emojiBlank() {
    return ShareUI.isDarkMode ? ShareUI.#emoji.squares.black : ShareUI.#emoji.squares.white;
  }
  static get emojiGridBG() {
    return ShareUI.#emoji.squares.blue;
  }
  static get emojiFail() {
    return ShareUI.#emoji.squares.red;
  }
  static get emojiPartial() {
    return ShareUI.#emoji.squares.yellow;
  }
  static get emojiSuccess() {
    return ShareUI.#emoji.squares.green;
  }
}
