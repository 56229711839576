/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */
import { times, trimFirstAndLast, adjacent, followDown, followRight } from './_utils.js';
import ShareUI from './share-ui.js';

const charToTD = char => {
  const td = document.createElement('td');
  if (char && char === '+') {
    td.innerText = char;
    td.dataset.status = 'eligible';
  }
  else if (char && char !== '.') {
    td.innerText = char;
    td.dataset.status = 'placed';
  }
  return td;
}
const wrapIn = (tag, ...children) => {
  const element = document.createElement(tag);
  element.append(...children);
  return element;
}

export default class Grid {
  /**
   * table#grid { --column-count; } tbody tr td[data-status][data-problem]
   * 
   * ['placed', 'eligible', undefined].includes(td.dataset.status)
   * ['length', 'spelling', 'disconnected', undefined].includes(td.dataset.problem)
   * ['', '+', 'A', ..., 'Z'].includes(td.innerText)
   */
  #element = document.querySelector('#grid');
  #game;

  constructor(game) {
    this.#game = game;
    this.#listen();
  }

  reset() {
    this.#element.querySelectorAll('td[data-status="placed"]').forEach(td => this.#returnToPool(td));
  }
  get #words() {
    const WORD_STARTER_TYPES = { VERTICAL: 1, HORIZONTAL: 2, BOTH: 3, NEITHER: 0 };
    const testWordStarter = td => {
      const adj = adjacent(td);
      const isVerticalStarter = (
        adj.up?.dataset.status !== 'placed' &&
        adj.down?.dataset.status === 'placed'
      );
      const isHorizontalStarter = (
        adj.left?.dataset.status !== 'placed' &&
        adj.right?.dataset.status === 'placed'
      );
      if (isVerticalStarter && isHorizontalStarter) {
        return WORD_STARTER_TYPES.BOTH;
      }
      if (isVerticalStarter && !isHorizontalStarter) {
        return WORD_STARTER_TYPES.VERTICAL;
      }
      if (!isVerticalStarter && isHorizontalStarter) {
        return WORD_STARTER_TYPES.HORIZONTAL;
      }
      return WORD_STARTER_TYPES.NEITHER;
    };
    return [...this.#element.querySelectorAll('td[data-status="placed"]')]
      .map(td => {
        const typeOfWordStarter = testWordStarter(td);
        switch (typeOfWordStarter) {
          case WORD_STARTER_TYPES.BOTH:
            return [followDown(td), followRight(td)];
          case WORD_STARTER_TYPES.VERTICAL:
            return [followDown(td)];
          case WORD_STARTER_TYPES.HORIZONTAL:
            return [followRight(td)];
          case WORD_STARTER_TYPES.NEITHER:
          default:
            return [];
        }
      })
      .flat();
  }
  highlightProblems() {
    console.debug('Checking word validity.');
    const usedInWords = this.#words.flat();
    const placed = [...this.#element.querySelectorAll('td[data-status="placed"]')];
    const notUsedInWords = placed
      .filter(td => !usedInWords.includes(td))
      .map(x => [x])
    ;
    [
      ...this.#words,
      ...notUsedInWords,
    ].forEach(word => {
      const text = word.map(tdEl => tdEl.innerText).join('').toLowerCase();
      console.debug(`Evaluating word "${text}"`);
      const problem = this.#game.problemWithWord(text);
      switch (problem) {
        case 'length':
          console.debug('Word is too short.', text);
          word.forEach(tdEl => tdEl.dataset.problem = 'length');
        break;
        case 'spelling':
          console.debug('Word is not in wordlist.', text);
          word.forEach(tdEl => tdEl.dataset.problem = 'spelling');
        break;
        case null:
        default:
          console.debug('Word is not a problem.');
          break;
      }
    });

    const connectedStarter = this.#element.querySelector('td[data-status="placed"]:not([data-problem])');
    if (!connectedStarter) {
      console.debug('No non-problematic words found. Skipping connectedness check.');
      return;
    }

    console.debug('Checking grid connectedness.');
    const notVerifiedConnected = placed.filter(td => td !== connectedStarter);
    const verifyConnected = td => {
      const connectedTDs = Object.values(adjacent(td)).filter(Boolean).filter(adjacentTd => notVerifiedConnected.includes(adjacentTd));
      connectedTDs.forEach(tdToRemove => {
        notVerifiedConnected.splice(notVerifiedConnected.indexOf(tdToRemove), 1);
        verifyConnected(tdToRemove);
      });
    };
    verifyConnected(connectedStarter);
    notVerifiedConnected.forEach(td => {
      console.debug('Cell is not connected to main grid.', td);
      td.dataset.problem = 'disconnected';
    });
  }
  removeProblems() {
    this.#element.querySelectorAll('td[data-problem]').forEach(td => delete td.dataset.problem);
  }
  get letterCount() {
    return this.#element.querySelectorAll('td[data-status="placed"]').length;
  }
  get problemCount() {
    return this.#element.querySelectorAll('td[data-problem]').length;
  }
  load(string) {
    this.#element.replaceChildren(
      wrapIn(
        'tbody',
        ...string
          .split('-')
          .map(rowString =>
            wrapIn('tr', ...rowString.split('').map(charToTD))
          )
      )
    );
    this.update();
  }
  #mapData(rowSeparator, tdTransformer) {
    return [...this.#element.querySelectorAll('tr')].map(tr =>
      [...tr.querySelectorAll('td')]
      .map(tdTransformer)
      .filter(trimFirstAndLast)
      .join('')
    ).filter(trimFirstAndLast).join(rowSeparator);
  }
  get string() {
    return this.#mapData(
      '-',
      td => td.innerText || '.'
    );
  }
  get emoji() {
    return this.#mapData(
      '\n',
      td =>
        (!td.dataset.status || td.dataset.status === 'eligible')
        ? ShareUI.emojiBlank
        : td.dataset.problem
          ? ShareUI.emojiFail
          : ShareUI.emojiGridBG
    );
  }

  #listen() {
    this.#element.addEventListener('click', event => {
      if (event.target.matches('td') && !this.#game.isOver) {
        return this.#letterClicked(event.target);
      }
    })
  }
  #letterClicked(td) {
    if (!td.dataset.status) {
      console.debug('This is an inactive grid square.', td);
      return;
    }
    if (td.dataset.status === 'placed') {
      if (this.#game.isPlacing) {
        console.debug('You cannot place a letter over an existing letter.', td);
        return;
      }
      console.debug('You would like to return this letter to the pool.', td);
      return this.#returnToPool(td);
    }
    if (td.dataset.status === 'eligible') {
      if (!this.#game.isPlacing) {
        console.debug('You are not currently placing a letter.', td);
        return;
      }
      console.debug('You are placing the active letter here.', td);
      return this.#finishPlacing(td);
    }
    throw new Error('Unexpected grid cell status!');
  }
  #returnToPool(td) {
    this.#game.pool.returnLetter(td.innerText);
    td.innerText = '';
    delete td.dataset.status;
    this.#game.moveMade();
  }
  #finishPlacing(td) {
    td.dataset.status = 'placed';
    td.innerText = this.#game.pool.usePlacing();
    this.#game.isPlacing = false;
    this.#game.moveMade();
  }
  update() {
    console.debug('Resetting placement eligibility.');
    this.#element.querySelectorAll('td').forEach(td => {
      if (td.dataset.status !== 'eligible') {
        return;
      }
      delete td.dataset.status;
      td.innerText = '';
    });

    console.debug('Trimming empty edge columns.');
    const trimColumn = selector => {
      const column = [...this.#element.querySelectorAll('td')].filter(td => td.matches(selector));
      const containsPlaced = column.find(td => td.dataset.status === 'placed');
      if (column.length === 0 || containsPlaced) {
        return;
      }
      column.forEach(cell => cell.remove());
      trimColumn(selector);
    }
    trimColumn(':first-child');
    trimColumn(':last-child');

    console.debug('Trimming empty edge rows.');
    const trimRow = selector => {
      const row = this.#element.querySelector(selector);
      if (!row || [...row.querySelectorAll('td')].find(td => td.dataset.status === 'placed')) {
        return;
      }
      row.remove();
      trimRow(selector);
    };
    trimRow('tr:first-child');
    trimRow('tr:last-child');

    const tbody = this.#element.querySelector('tbody');

    if (tbody.querySelectorAll('td').length === 0) {
      console.debug('No more grid cells. Placing one eligible cell.');
      const newRow = document.createElement('tr');
      const newCell = document.createElement('td');
      newCell.innerText = '+';
      newCell.dataset.status = 'eligible';
      newRow.append(newCell)
      tbody.append(newRow);
      this.#element.style.setProperty('--column-count', 1);
      this.#game.letterWidth = 3.00; 
      return;
    }

    console.debug('Adding new empty edge rows.');
    const meaningfulColumnCount = this.#element.querySelectorAll('tr:first-child td').length;
    ['append', 'prepend'].forEach(method => {
      const newRow = document.createElement('tr');
      times(meaningfulColumnCount).forEach(() => newRow.append(document.createElement('td')));
      tbody[method](newRow);
    });

    console.debug('Adding new empty edge columns.');
    tbody.querySelectorAll('tr').forEach(row => {
      row.prepend(document.createElement('td'));
      row.append(document.createElement('td'));
    });

    console.debug('Measuring new grid width.');
    const columnCount = this.#element.querySelectorAll('tr:first-child td').length;
    this.#element.style.setProperty('--column-count', columnCount);
    const widths = [
      3.00, 3.00, 3.00, 3.00, 3.00, 3.00, 3.00,
      3.00, 2.75, 2.375, 2.125, 2.00, 1.8125, 1.6875, 1.6875
    ]
    this.#game.letterWidth = widths[columnCount] || 3.00;

    const hasAdjacentPlaced = tdEl => {
      return Boolean(
        Object.values(adjacent(tdEl)).find(cell => cell?.dataset.status === 'placed')
      );
    };
    tbody.querySelectorAll('td').forEach(cell => {
      if (cell.dataset.status === 'placed') {
        return;
      }
      if (hasAdjacentPlaced(cell)) {
        cell.dataset.status = 'eligible';
        cell.innerText = '+';
      }
    })
  }
}
