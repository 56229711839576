/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */
import prng from './_prng.js';
import seededShuffle from './_shuffle-seed.js';
import DICE from './dice.js';
import ShareUI from './share-ui.js';

const charToLI = char => {
  const li = document.createElement('li');
  if (char && char !== '.') {
    li.innerText = char;
  }
  else {
    li.dataset.status = 'used';
  }
  return li;
}

export default class Pool {
  /**
   * ul#pool li[data-status]
   * 
   * ['placing', 'used', undefined].includes(li.dataset.status)
   * ['', 'A', ..., 'Z'].includes(li.innerText)
   */
  #element = document.querySelector('#pool');
  #game;

  constructor(game) {
    this.#game = game;
    this.#listen();
  }

  generate(seed) {
    const seededPRNG = prng(seed);
    this.#element.replaceChildren(
      ...seededShuffle(DICE, seededPRNG)
        .map(letter => seededShuffle(letter, seededPRNG)[0])
        .map(charToLI)
    );
  }
  shuffle() {
    this.#element.replaceChildren(
      ...seededShuffle(
        [...this.#element.querySelectorAll('li')],
        () => Math.random()
      )
    );
  }
  load(string) {
    this.#element.replaceChildren(
      ...string
        .split('')
        .map(charToLI)
    );
  }
  get string() {
    return [...this.#element.querySelectorAll('li')].map(li => li.innerText || '.').join('');
  }
  get emoji() {
    const unsplit = [...this.#element.querySelectorAll('li')].map(li =>
      li.dataset.status !== 'used'
        ? ShareUI.emojiFail
        : this.#game.isVictorious
          ? ShareUI.emojiSuccess
          : ShareUI.emojiPartial
    ).join('');
    const half = Math.floor(unsplit.length / 2) ;
    return unsplit.substring(0, half) + '\n' + unsplit.substring(half);
  }
  get letterCount() {
    return this.#element.querySelectorAll('li').length;
  }
  get unusedCount() {
    return this.#element.querySelectorAll('li:not([data-status="used"])').length;
  }
  returnLetter(str) {
    const firstUsed = this.#element.querySelector('li[data-status="used"]');
    firstUsed.innerText = str;
    delete firstUsed.dataset.status;
  }
  usePlacing() {
    const placing = this.#element.querySelector('li[data-status="placing"]');
    const str = placing.innerText;
    placing.innerText = '';
    placing.dataset.status = 'used';
    return str;
  }

  #listen() {
    this.#element.addEventListener('click', event => {
      if (event.target.matches('li') && !this.#game.isOver) {
        return this.#letterClicked(event.target);
      }
    })
  }
  #letterClicked(li) {
    if (li.dataset.status === 'used') {
      console.debug('This letter has already been used.', li);
      return;
    }
    if (this.#game.isPlacing) {
      if (li.dataset.status === 'placing') {
        console.debug('You were placing this letter.', li);
        return this.#stopPlacing(li);
      }
      console.debug('You were placing another letter.', li);
      return this.#switchToPlacing(li);
    }
    console.debug('You would like to begin placing this letter.', li);
    return this.#beginPlacing(li);
  }
  #stopPlacing(li) {
    this.#game.isPlacing = false;
    delete li.dataset.status;
  }
  #beginPlacing(li) {
    this.#game.isPlacing = true;
    li.dataset.status = 'placing';
  }
  #switchToPlacing(li) {
    delete this.#element.querySelector('li[data-status="placing"]').dataset.status;
    li.dataset.status = 'placing';
  }
}
