/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */
import { msToChunks, padTimeChunk, tail, MS_PER_DAY } from './_utils.js';

export default class Data {
  /**
   * #statistics #countdown #countdown-time,
   * #statistics #streaks #streaks-played #streaks-played-value,
   * #statistics #streaks #streaks-winpercent #streaks-winpercent-value,
   * #statistics #streaks #streaks-current #streaks-current-value,
   * #statistics #streaks #streaks-max #streaks-max-value
   */
  #element = document.querySelector('#statistics');
  #game;

  constructor(game) {
    this.#game = game;
  }

  transitionToPostGame() {
    this.save();
    this.#updateStatistics();
    this.#updateTimer();
    window.setInterval(() => this.#updateTimer(), 1000);
  }
  save() {
    const stats = Object.assign({}, this.#storedSaveData);
    if (!this.#game.iteration) {
      throw new Error('You must not call save() before the daily iteration has been calculated.');
    }

    const data = {
      win: this.#game.isVictorious,
      unused: this.#game.pool.unusedCount,
      seed: this.#game.seed,
      save: this.#saveString,
    };
    console.debug(`Saving state save state for iteration ${this.#game.iteration}.`, data);
    stats[this.#game.iteration] = data;
    this.#storedSaveData = stats;
  }
  load() {
    console.debug('Attempting to load your existing game...');
    const todaysState = this.#storedSaveData?.[this.#game.iteration]?.save;
    if (!todaysState) {
      console.debug(`No save state exists for iteration ${this.#game.iteration}.`);
      return;
    }
    console.debug(`Save state for iteration ${this.#game.iteration} exists.`, todaysState);
    const [pool, grid] = todaysState.split('---');
    if (!pool || !grid) {
      console.warn(`Save state for iteration ${this.#game.iteration} is malformed. Stopping load.`);
      return;
    }
    console.debug('Loading pool save state.', pool);
    this.#game.pool.load(pool);
    console.debug('Loading grid save state.', grid);
    this.#game.grid.load(grid);
  }

  get #saveString() {
    return `${this.#game.pool.string}---${this.#game.grid.string}`;
  }
  set #saveString(value) {
    throw new Error('You are not allowed to set #saveString.', value);
  }
  get #storedSaveData() {
    return JSON.parse(window.localStorage.getItem('saveData'));
  }
  set #storedSaveData(value) {
    window.localStorage.setItem('saveData', JSON.stringify(value));
    return value;
  }
  #updateStatistics() {
    const entries = Object.entries(this.#storedSaveData);
    const played = entries.length;
    const won = entries.filter(([iteration, day]) => day.win).length;
    const winpercent = Math.round((won / played) * 100);
    const sorted = entries
      .filter(([iteration, day]) => day.win)
      .sort(([iterationA, dayA], [iterationB, dayB]) => parseInt(iterationB) - parseInt(iterationA))
    ;

    const streaks = [];
    let tempStreak = [];
    for (const [iteration, day] of sorted) {
      if (tempStreak.length === 0) {
        tempStreak.push(parseInt(iteration));
        streaks.push(tempStreak);
        continue;
      }
      const int = parseInt(iteration);
      if (tail(tempStreak) === int + 1) {
        tempStreak.push(int);
        continue;
      }
      tempStreak = [parseInt(iteration)];
      streaks.push(tempStreak);
    }

    const currentStreak = streaks[0]?.[0] === parseInt(this.#game.iteration) ? streaks[0].length : 0;
    const maxStreak = streaks.reduce((prev, streak) => Math.max(prev, streak.length), 0);

    console.debug('Updating statistics.', entries, sorted, streaks);
    this.#element.querySelector('#streaks-played-value').innerText = played;
    this.#element.querySelector('#streaks-winpercent-value').innerText = winpercent;
    this.#element.querySelector('#streaks-current-value').innerText = currentStreak;
    this.#element.querySelector('#streaks-max-value').innerText = maxStreak;
  }
  get #msUntilReset() {
    const now = new Date();
    const lastReset = new Date(this.#game.seed + 'T00:00:00Z');
    const nextReset = new Date(Number(lastReset) + MS_PER_DAY);
    return nextReset - now;
  }
  #updateTimer() {
    const { hours, minutes, seconds } = msToChunks(this.#msUntilReset);
    this.#element.querySelector('#countdown-time').innerText = `${padTimeChunk(hours)}:${padTimeChunk(minutes)}:${padTimeChunk(seconds)}`;
  }
}
