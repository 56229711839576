/*!
 * Cross ’em
 * Copyright 2022 Kerrick Long
 * All Rights Reserved.
 * https://cross-em.com
 */

import Game from './lib/game.js';

window.game = new Game();

if (!window.localStorage.getItem('hasViewedInstructions')) {
  document.querySelector('#instructions').classList.add('visible');
}
document.querySelector('#open-instructions').addEventListener('click', () => {
  document.querySelector('#instructions').classList.add('visible');
})
document.querySelector('#close-instructions').addEventListener('click', () => {
  document.querySelector('#instructions').classList.remove('visible');
  window.localStorage.setItem('hasViewedInstructions', true);
});